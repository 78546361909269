$grey:#3e4c59;
$gray: #292929;
$dark:#303030;
$darker: #111111;
$darkGrey:#9AA5B1;
$lightGrey: #D3D5D8;
$black:#000000;
$light:#f5f7fd;
$white:#ffffff;
$lighter: #f5f7fa;
$green: #05A357;
$smallScreens: 768px;
$mediumScreens: 1300px;


body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  padding-top: 4em;
}


.wrapper {
  margin: 0 10em;
  @media only screen and (max-width: $smallScreens) {
    margin: 0em;
    padding: 0 1em
  } 

  @media only screen and (max-width: $mediumScreens) {
    margin: 0em 2em;
  } 
}


.container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  @media only screen and (max-width: $smallScreens) {
    display: block;
  } 
}

.grid-col-3{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  @media only screen and (max-width: $smallScreens) {
    display: block;
  } 
  @media only screen and (max-width: $mediumScreens) {
    grid-template-columns: repeat(2, 1fr);
  } 
}

.capitalize {
  text-transform: capitalize;
}

.menu {
  padding: 1em;
  width: 13em;
  color: $grey;
  margin-bottom: 1px;

   &.full{
    width: 100%;
   }

   &:hover{
    background-color: $light;
    color: $black;
    font-weight: 600;
    font-style: italic;
    cursor: not-allowed;
   }

   &:first-child{
    background-color: $light;
    color: $black;
    font-weight: 600;
    cursor: pointer;
   }
}

header {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 1em 0;
  background-color: $white;
  @media only screen and (max-width: $smallScreens) {
    position: fixed;
    top: 0;
    width: 100%;
  }
}

.avatar{
  height: 2em;
  width: 2em;
  border-radius: 100%;
  background-color: $darkGrey;
  display: flex;
  place-content: center;
  place-items: center;
  font-weight: 600;
}

.avatar-2{
  height: 2em;
  width: 2em;
  border-radius: 100%;
  background-color: $green;
  display: flex;
  place-content: center;
  place-items: center;
  font-weight: 600;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  gap: 10px; 
}

.flex-2{
  display: flex;
  align-content: center;
  align-items: center;
  gap: 10px; 
}

.top{
  @media only screen and (max-width: $smallScreens) {
    margin-top: 2em;
  }
}

.desktop{
  @media only screen and (max-width: $smallScreens) {
    display: none;
  }
}

.mobile{
  @media only screen and (max-width: $smallScreens) {
    display: block;
  }
  display: none;
}

.bold {
  font-weight: 600;
}

.m-4 {
  margin-top: 2em;
}

.border-b{
  border-bottom: 1px solid $lightGrey;
}

.each-wallet {
  padding: 1em 1.5em;
  outline: 1px soild $black;
  background-color: $darker;
  background: linear-gradient(-50deg, $gray, $black);
  border-radius: 0.5em;
  margin: 0.25em 0;

  & h4{
    color: $darkGrey;
    font-weight: 400;
  }

  & p {
    color: $white;
    font-weight: 600;
    font-size: 1.2em;
  }

  & .footer {
    display: flex;
    justify-content: end;

    & .circle {
      border-radius: 100%;
      height: 2em;
      width: 2em;
      background-color: $dark;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      display: flex;
      place-content: center;
      place-items: center;
    }
    
  }

  &::after{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
          135deg,
          rgba(255, 255, 255, 0.05) 25%,
          transparent 5%
        ),
        linear-gradient(
          45deg,
          rgba(255, 255, 255, 0.05) 25%,
          transparent 5%
        );
      background-size: 200% 200%;
      mix-blend-mode: overlay;
      pointer-events: none;
  }

}

.h-screen{
  height: 12em;
  display: flex;
  place-content: center;
  place-items: center;
}

text-center {
  text-align: center;
}

.centre {
  display: flex;
  place-content: center;
  place-items: center;
}

.brand-button {
  background-color: $black;
  color: $white;
  padding: 1em 2em;
  border-radius: 10px;
  width: 100%;
}

button {
  background: none;
  border: none;
  padding: 0;
  font: inherit; /* Inherit font styles from parent */
  color: inherit; /* Inherit text color */
  cursor: pointer;
}
